import React, { Component } from 'react';
import './reset-password.scss';

export default class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailInput: ''
		};
	}

	onSubmit() {
		const data = {
			email: this.state.emailInput
		};

		this.props.onSubmit(data);
	}

	render() {
		return (
			<div className="reset-form">
				<div className="control-group">
					<input
						type="text"
						className="settings-field"
						onChange={(ev) => {
							this.setState({ emailInput: ev.target.value });
						}}
						value={this.state.emailInput}
						placeholder="email"
						id="email" />
				</div>
				<a
					className="btn btn-primary btn-large btn-block"
					href="#"
					onClick={this.onSubmit.bind(this)}
				>{'reset password'}
				</a>
				<a className="sign-link" href={'/'}>{'Changed your mind?'}</a>
			</div>);
	}
}
