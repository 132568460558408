import React, { Component } from 'react';
import dogSvg from '../../images/dog.svg';
import dogsImg from '../../images/dogs.jpg';
import realtimeJpg from '../../images/realtime.jpg';
import historyJpg from '../../images/history.jpg';
import fenceJpg from '../../images/fence.jpg';
import { apiLogin } from '../api/index';

export default class Info extends Component {
	onLoginSubmit(payload) {
		apiLogin({
			payload,
			success: () => {
				window.location.href = '/';
			},
			error: (error) => {
				alert(`Woof-Woof! ${error.data.message}!`);
			}
		});
	}

	render() {
		return (<div className='info-content'>
			<h4>What the woof!?</h4>
			<p>
				whereismy.dog is a simple pet tracking app.
				It allows pet owners to track their pet's position and movement in real time.
			</p>
			<h4>How it works?</h4>
			<p>It uses small gps tracker that can be attached to pet's collar. If turned on, it will continuously send pet's location over gsm to whereismy.dog application.
				This tracker is waterproof and powered by battery, that can last up to 24 hours.</p>
			<img
				className='coco-chili'
				src={dogsImg}/>
			<small>Coco and Chili with trackers</small>
			<h4>Features:</h4>
			<ul>
				<li>Real time tracking</li>
				<img
					className='coco-chili'
					src={realtimeJpg}/>
				<li>Movement history</li>
				<img
					className='coco-chili'
					src={historyJpg}/>
				<li>Geofence (Owner can create virtual fence and if pet runs out of that fence, it will notify owner via email)</li>
				<img
					className='coco-chili'
					src={fenceJpg}/>
			</ul>
			<h4>How can i get one?</h4>
			<p>Contact rando.valimets@gmail.com for more information.</p>
			<a
				href='/register'
				className='what-the-woof'
			>
				<img
					src={dogSvg}/>Register!</a>
		</div>);
	}
}
