import React, { Component, Fragment } from 'react';
import Doggo from '../components/doggo';
import dogSvg from '../../images/dog.svg';
import RegisterLogin from '../components/register-login';
import { apiLogin } from '../api/index';

export default class Login extends Component {
	onLoginSubmit(payload) {
		apiLogin({
			payload,
			success: () => {
				window.location.href = '/';
			},
			error: (error) => {
				alert(`Woof-Woof! ${error.data.message}!`);
			}
		});
	}

	render() {
		return (<Fragment>
			<a
				className='what-the-woof'
				href='/about'
			>
				<img
					src={dogSvg}/>What the woof!?</a>
			<Doggo />
			<RegisterLogin
				onSubmit={this.onLoginSubmit.bind(this)}
			/>
		</Fragment>);
	}
}
