import React, { Component, Fragment } from 'react';
import Doggo from '../components/doggo';
import dogSvg from '../../images/dog.svg';
import RegisterLogin from '../components/register-login';
import { apiRegister } from '../api/index';

export default class Register extends Component {
	onRegisterSubmit(payload) {
		apiRegister({
			payload,
			success: (user) => {
				window.location.href = '/';
			},
			error: (error) => {
				alert(`Woof-Woof! ${error.data.message}!`);
			}
		});
	}

	render() {
		return (<Fragment>
			<a
				className='what-the-woof'
				href='/about'
			>
				<img
					src={dogSvg}/>What the woof!?</a>
			<Doggo />
			<RegisterLogin
				onSubmit={this.onRegisterSubmit.bind(this)}
				register={true}>
			</ RegisterLogin>
		</Fragment>);
	}
}
