import 'whatwg-fetch';

const apiRequest = (url, options, reqOpts) => {
	let error;

	return fetch(url, reqOpts)
		.then((response) => {
			if (!response.ok) {
				error = {
					status: response.status,
					statusText: response.statusText
				};
			}
			return response.json();
		}).then((response) => {
			if (error) {
				error.data = response;
				options.error(error);
			} else {
				options.success(response);
			}

			return response;
		});
};

const getDefaultReqOpts = () => {
	return {
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache-control': 'no-cache',
			'Pragma': 'no-cache'
		}
	};
};

const apiGet = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	apiRequest(url, options, reqOpts);
};

function apiPut(url, options) {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'PUT';
	reqOpts.body = JSON.stringify(options.payload);

	apiRequest(url, options, reqOpts);
}

const apiPost = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'POST';
	reqOpts.body = JSON.stringify(options.payload);

	apiRequest(url, options, reqOpts);
};

const apiDelete = (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'DELETE';

	return apiRequest(url, options, reqOpts);
};

export const apiRegister = options => apiPost('/auth/register', options);

export const apiResetPassword = options => apiPost('/auth/reset', options);

export const apiLogin = options => apiPost('/auth/login', options);

export const getUserSelf = options => apiGet('/api/self', options);

export const apiPutUser = (id, options) => apiPut(`/api/user/${id}`, options);

export const getDogs = options => apiGet('/api/dogs', options);

export const apiPostDog = options => apiPost('/api/dogs', options);

export const apiPutDog = (id, options) => apiPut(`/api/dog/${id}`, options);

export const apiDeleteDog = (id, options) => apiDelete(`/api/dog/${id}`, options);

export const getFences = options => apiGet('/api/fences', options);

export const apiPostFence = options => apiPost('/api/fences', options);

export const apiPutFence = (id, options) => apiPut(`/api/fence/${id}`, options);

export const apiDeleteFence = (id, options) => apiDelete(`/api/fence/${id}`, options);

export const apiGetLocations = (id, from, to, options) => apiGet(`/api/dog/${id}/locations?from=${from}&to=${to}`, options);
