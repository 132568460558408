import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './login';
import Register from './register';
import Reset from './reset-password';
import Info from './info';
import './landing.scss';

export default function LandingRouter() {
	return (
		<Router>
			<Fragment>
				<Route exact path="/" component={Login} />
				<Route path="/register" component={Register} />
				<Route path="/about" component={Info} />
				<Route path="/reset-password" component={Reset} />
			</Fragment>
		</Router>
	);
}
