import React, { Component, Fragment } from 'react';
import Doggo from '../components/doggo';
import dogSvg from '../../images/dog.svg';
import ResetPassword from '../components/reset-password';
import { apiResetPassword } from '../api/index';

export default class Reset extends Component {
	onResetSubmit(payload) {
		apiResetPassword({
			payload,
			success: () => {
				alert('Your password has been successfully reset! Check your email for new password!');

				window.location.href = '/';
			},
			error: (error) => {
				alert(`Woof-Woof! ${error.data.message}!`);
			}
		});
	}

	render() {
		return (<Fragment>
			<a
				className='what-the-woof'
				href='/about'
			>
				<img
					src={dogSvg}/>What the woof!?</a>
			<Doggo />
			<ResetPassword
				onSubmit={this.onResetSubmit.bind(this)}
			>
			</ ResetPassword>
		</Fragment>);
	}
}
