import React, { Component } from 'react';
import './register-login.scss';

export default class RegisterLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailInput: '',
			passwordInput: ''
		};
	}

	onSubmit() {
		const data = {
			email: this.state.emailInput,
			password: this.state.passwordInput
		};

		this.props.onSubmit(data);
	}

	render() {
		return (
			<div className="login-form">
				<div className="control-group">
					<input
						type="text"
						className="settings-field"
						onChange={(ev) => {
							this.setState({ emailInput: ev.target.value });
						}}
						value={this.state.emailInput}
						placeholder="email"
						id="email" />
				</div>
				<div className="control-group">
					<input
						type="password"
						className="settings-field"
						onChange={(ev) => {
							this.setState({ passwordInput: ev.target.value });
						}}
						value={this.state.passwordInput}
						placeholder="password"
						id="password" />
				</div>
				<a
					className="btn btn-primary btn-large btn-block"
					href="#"
					onClick={this.onSubmit.bind(this)}
				>{this.props.register ? 'register' : 'login'}
				</a>
				<a className="sign-link" href={'/reset-password'}>{'Forgot password?'}</a>
				<a className="sign-link" href={this.props.register ? '/' : '/register'}>{this.props.register ? 'Already have an account?' : 'Dont have an account?'}</a>
			</div>);
	}
}
